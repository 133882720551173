ion-split-pane {
    --side-max-width: auto;
    max-width: 100%;
}

@media (min-width: 992px) {
    ion-menu ion-content {
        display: block;
    }
    ion-menu {
        width: 270px;
    }
    ion-split-pane > .ion-page {
        margin-left: 270px;
    }
    #inbox-list {
        padding-top: 0;
    }
    ion-menu ion-list-header {
        margin-top: -10px;
        padding-bottom: 10px;
    }
    .ios ion-menu ion-list-header {
        margin-top: 0px;
    }
    ion-split-pane > .ion-page ion-toolbar ion-title {
        visibility: hidden;
    }
}

ion-split-pane > .ion-page .container-fluid {
    min-height: calc(100% - 54px);
}

.justify-content-between {
    justify-content: space-between;
}

.link, ion-item {
    cursor: pointer;
}

.link:hover, .link:active {
    text-decoration: underline;
}