/* Table design */
.table-pane {
    max-width: 100%;
    overflow-x: auto;
}

/* Loading pane an animation */
.loading-backdrop {
    background-color: #b4b4b480;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99998;
}
.loading-box {
    position: fixed;
    z-index: 99999;
    width: 50%;
    padding: 30px 0;
    left: 25%;
    top: calc(50% - 57px);
    background-color: rgba(43, 109, 43, 0.7);
    display: flex;
    justify-content: center;
}
.loading-image {
    width: 54px;
    animation: loading-animation 1000ms;
    animation-iteration-count: infinite;
}
@keyframes loading-animation {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(1turn);
    }
}

/* Payment Box */
.payment-box {
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.4);
}

.payment-box iframe {
    border: 0;
    margin: 0;
    padding: 0;
    outline: 0;
    width: 100%;
    height: calc(100% - 48px);
}